import React from "react";
import Carousel from "react-multi-carousel";
import Classes from "./commonComponents.module.scss";
import classNames from "classnames";
import Google from "../../../utils/images/Google.png";
import { MobileTextField } from "../MoreInfoSolutions/MainInfoSolutionsComponent";
import { isMobile, isTablet } from "react-device-detect";
import LocationMapGIF from "../../../utils/images/LocationMapGIF.gif";
import LocationMapPinGIF from "../../../utils/images/LocationMapPinGIF.gif";
import {
  ImagesSectionData,
  TeamPhotos,
} from "../../../utils/constants/constantData";
import { Modal, Carousel as CarouselReactBootsrap } from "react-bootstrap";
import TeamPhoto from '../../../utils/images/TeamPhoto.jpg'
import Typewriter from "typewriter-effect";

export const MainHeadingAboutPage = ({ heading, description, className }) => {
  return (
    <div
      className={classNames(
        Classes.mainHeadingAboutPageContainer,
        className?.mainHeadingContainer
      )}
    >
      <h1 className={classNames(Classes.heading, className?.heading)}>
        {heading}
      </h1>
      <p className={classNames(Classes.description, className?.description)}>
        {description}
      </p>
    </div>
  );
};

export const GstInfoContainer = () => {
  return (
    <div className={Classes.gstInfoContainer}>
      <div>
        <p>
          <span>
            Taxeasy assigns a{" "}
            <span style={{ fontWeight: "600", fontSize: "1.3rem" }}>
              personal expert
            </span>{" "}
            to you who will resolve all your tax & business finance related
            queries & ensure all your compliances.
          </span>
        </p>
        <p>
          <span>
            Say goodbye to tax worries with our{" "}
            <span style={{ fontWeight: "600", fontSize: "1.3rem" }}>
              personalized assistance and expert guidance.
            </span>
          </span>
        </p>
      </div>
      <div className={Classes.callingInfo}>
        <a target="_blank" rel="noreferrer" href="tel:+91-9936818000">
          Call Now 9936818000
        </a>
      </div>
    </div>
  );
};

export const GoogleRating = () => {
  return (
    <div className={Classes.mainGoogleRatingContainer}>
      <p className={Classes.heading}>4.9 Star Google Rating</p>
      <div className={Classes.description}>
        <img src={Google} alt={"google-icon"} />
        <p>&#9733; &#9733; &#9733; &#9733; &#9733; </p>
      </div>
    </div>
  );
};

export const GoogleRatingWithMobile = () => {
  return (
    <div
      className={classNames(
        Classes.mainGoogleRatingContainer,
        Classes.mainGoogleRatingContainerWithMobile
      )}
    >
      <div>
        <p className={Classes.heading}>4.9 Star Google Rating</p>
        <div className={Classes.description}>
          <img src={Google} alt={"google-icon"} />
          <p>&#9733; &#9733; &#9733; &#9733; &#9733; </p>
        </div>
      </div>
      <div>
        <MobileTextField
          className={{ mobileInputContainer: Classes.mobileInputContainer }}
        />
      </div>
    </div>
  );
};

export const CarouselComponent = ({
  responsive,
  carouselCard,
  Children,
  ClassName,
}) => {
  return (
    <Carousel
      autoPlay={true}
      infinite={true}
      deviceType={isMobile ? "mobile" : isTablet ? "tablet" : "desktop"}
      containerClass={classNames(Classes.carouselContainer, ClassName)}
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      responsive={responsive}
      autoPlaySpeed={2000}
      partialVisible={true}
    >
      {carouselCard?.map((item, index) => (
        <Children key={`carousel Item ${item.key}-${index}`} item={item} />
      ))}
    </Carousel>
  );
};

export const ImageCard = ({ image, alt }) => {
  return (
    <div className={Classes.imageCard}>
      <img src={image} alt={alt} />
    </div>
  );
};

export const ImagesComponent = () => {
  return (
    <div className={Classes.imageComponentSection}>
      {ImagesSectionData?.map((data) => (
        <ImageCard image={data?.image} alt={data?.alt} key={data?.alt} />
      ))}
    </div>
  );
};

export const StepsSection = ({ image, alt, isLine, className }) => {
  return (
    <>
      <div className={classNames(Classes.stepsCircle, className?.stepsCircle)}>
        <img alt={alt} src={image} />
      </div>
      {isLine && (
        <div
          className={classNames(Classes.stepsLine, className?.stepsLine)}
        ></div>
      )}
      <div className={Classes.clear}></div>
    </>
  );
};

export const CitiesSection = ({ info, className }) => {
  return (
    <div
      className={classNames(
        Classes.nameofCitiesMainContainer,
        className?.nameofCitiesMainContainer
      )}
      // style={{height: '60px'}}
    >
      {/* <div className={Classes.gifContainer}>
        <img src={LocationMapGIF} alt="location-path-gif" />
        <img
          src={LocationMapPinGIF}
          style={{ position: "relative", left: "-125px" }}
          alt="location-pin-gif"
        />
      </div> */}
      <div
        className={classNames(Classes.cityContainer, className?.cityContainer)}
        style={{height: '60px'}}
      >
        {/* <div className={classNames(Classes.first, className?.first)}>
          {info?.slice(0, 9)?.map((info, index) => (
            <p key={`section1-cities-${index}`}>{info?.cityName}</p>
          ))}
        </div>
        <div className={classNames(Classes.second, className?.second)}>
          {info?.slice(9, info?.length)?.map((info, index) => (
            <p key={`section2-cities-${index}`}>{info?.cityName}</p>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export const AssociatedWithSection = ({ heading, info }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };
  return (
    <div className={Classes.assiciatedWithMainContainer}>
      <h2 className={Classes.heading}>{heading}</h2>
      <div className={Classes.imageSection}>
        <CarouselComponent
          responsive={responsive}
          carouselCard={info}
          Children={AssociatedCardImg}
          ClassName={Classes.carousel}
        />
      </div>
    </div>
  );
};

const AssociatedCardImg = ({ item }) => {
  return (
    <div className={Classes.associatedCard}>
      <img
        src={item?.image}
        style={{ width: "160px", height: "80px" }}
        alt={item?.alt}
      />
    </div>
  );
};

export const TeamPhotoCarouselComponent = ({ info }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={Classes.TeamPhotoCarouselComponentMainContainer}>
      <CarouselComponent
        responsive={responsive}
        carouselCard={info}
        Children={TeamPhotoCardImg}
        ClassName={Classes.teamCarousel}
      />
    </div>
  );
};

const TeamPhotoCardImg = ({ item }) => {
  return (
    <div className={Classes.teamPhotoCard}>
      <img
        src={item?.image}
        style={{ width: "100%", height: "300px" }}
        alt={item?.alt}
      />
    </div>
  );
};

export const ThankyouModal = ({ show, handleClose }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className={Classes.modalTitle}>Thank you</Modal.Title>
      </Modal.Header>
      <Modal.Body className={Classes.modalBody}>
        Your data has been submitted sucessfully... We will get back to you
        soon!
      </Modal.Body>
    </Modal>
  );
};

export const VedioAlongWithCarousel = ({ vedioLink }) => {
  return (
    <div className={Classes.videoAlongWIthCarouselContainer}>
      <div className={Classes.vedioContainer}>
        <iframe
          className={Classes.solutionsVedio}
          src={vedioLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      {!isMobile && (
        <div className={Classes.miniCarouselContainer}>
          <CarouselReactBootsrap
            data-bs-theme="dark"
            controls={false}
            indicators={false}
            interval={1000}
          >
            {TeamPhotos.map((item, index) => (
              <CarouselReactBootsrap.Item
                key={item.alt}
                className={Classes.carouselItem}
              >
                <img
                  src={item.image}
                  alt={item.alt}
                  className={classNames(Classes.carouselImg, "d-block w-100")}
                />
              </CarouselReactBootsrap.Item>
            ))}
          </CarouselReactBootsrap>
        </div>
      )}
    </div>
  );
};

export const AboutUsInfoComponent = () => {
  return (
    <div className={Classes.aboutUsContainer}>
        <div className={Classes.mainHeading}>
        <Typewriter
                      style={{ fontSize: "40px" }}
                      options={{
                        strings: ['TaxEasy - Your Gateway to Seamless Business Solution in India!'],
                        autoStart: true,
                        loop: true,
                      }}
                    />
        </div>
        <h3 className={Classes.heading}>ABOUT US</h3>
      <div className={Classes.descriptionContainer}>
      <p className={Classes.description}>
        At <b><em>Taxeasy Solutions Pvt Ltd</em></b>, we take pride in being the driving force
        behind countless successful businesses by offering comprehensive
        services related to Business Setup in India, Startup Legalities, ROC
        Filing, Tax Filings, Trademark Protection and more. As the trusted name
        behind TaxEasy, we bring you a world of expertise and dedication to
        simplify your journey through the intricacies of the Indian business
        landscape. At Taxeasy, our vision is to provide all startup & tax
        related services under one roof so that the entrepreneurs and
        enterprises can focus on their core competencies while leaving the
        complexities of compliance and regulations to us. We aim to be the
        catalyst that propels businesses toward success by providing efficient,
        reliable, and personalized solutions. At Taxeasy, we are not just a
        service provider; we are your partners in success. Join hands with us,
        and let's navigate the journey of entrepreneurship together. Welcome to
        Taxeasy – Where Business Success Begins!
      </p>
      <img src={TeamPhoto} alt={'team-photo'}/>
      </div>
    </div>
  );
};
