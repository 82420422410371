// import ContactUs from "../commonComponents/ContactUs/ContactUs"
// import { itrStepList } from "../../utils/constants/constantData"
import Classes from './thankyou.module.scss'
import TickIcon from '../../utils/images/Step3.gif'

const ThankyouComponent = ()=>{
    return (
        <>
            <div className={Classes.thankyouContainer}>
                <h1 className={Classes.heading}>Thank You</h1>
                <div><img src={TickIcon} alt='tick-mark' className={Classes.thankyouImg}/></div>
                <p className={Classes.description}>Thanks For Contacting us. Your message has been sent Successfully. We will get back to you shortly !</p>
            </div>
            {/* <ContactUs stepInfo={itrStepList} /> */}
        </>
    )
}

export default ThankyouComponent