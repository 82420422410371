import React from "react";
import {
  CitiesData,
  AssociatedWithData,
  itrStepList,
  HOME_FAQ,
  itrCustomerInfo,
  itrCustomerReview,
  Home_Services,
} from "../../utils/constants/constantData";
import {
  CitiesSection,
  AssociatedWithSection,
  ImagesComponent,
} from "../commonComponents/SomeCommonComponents/SomeCommonCompoents";
import ITRComponent, { FileYourITRComponent } from "../ITR/ITRComponent";
import StepsComponent from "../commonComponents/Steps/StepsComponent";
import ContactUs from "../commonComponents/ContactUs/ContactUs";
import FAQComponent from "../commonComponents/FAQ/FAQComponent";
import CustomerReviewComponent from "../commonComponents/CustomerReviews/CustomerReviewComponent";
import WhyTaxEasyComponent from "../commonComponents/WhytaxEasy/WhyTaxEasyComponent";
import Services from "../commonComponents/Services/Services";
import { isMobile } from "react-device-detect";

const Homecomponent = () => {
  return (
    // <div>
    //   <CitiesSection info={CitiesData} />
    //   <AssociatedWithSection
    //     heading={"We Associated With"}
    //     info={AssociatedWithData}
    //   />
    //   <FileYourITRComponent heading="ITR FILING SEASON IS ON !" isHome="true" />
    //   {!isMobile && <StepsComponent stepList={itrStepList} />}
    //   <Services services={Home_Services} />
    //   <WhyTaxEasyComponent
    //     heading={"Why TaxEasy?"}
    //     description={
    //       "We are admired by people for our Exceptional Customer Satisfaction !"
    //     }
    //     customerInfo={itrCustomerInfo}
    //   />
    //   {/* <ImagesComponent /> */}
    //   <CustomerReviewComponent customerReview={itrCustomerReview} />
    //   <FAQComponent
    //     heading={"Frequently Asked Questions"}
    //     description={"We have got you covered ! Call Now !"}
    //     QuestionsList={HOME_FAQ}
    //   />
    //   <ContactUs stepInfo={itrStepList} />
    // </div>
    <ITRComponent/>
  );
};

export default Homecomponent;
