import React from 'react'
import { isMobile } from 'react-device-detect'
// import ITRComponent from '../ITR/ITRComponent'
// import Classes from './contactRoute.module.scss'
import { CitiesData, AssociatedWithData, TeamPhotos, aboutStepList} from '../../utils/constants/constantData'
import { CitiesSection, AssociatedWithSection, TeamPhotoCarouselComponent, AboutUsInfoComponent } from '../commonComponents/SomeCommonComponents/SomeCommonCompoents'
// import { FileYourITRComponent } from '../ITR/ITRComponent'
// import StepsComponent from '../commonComponents/Steps/StepsComponent'
// import ContactUs from '../commonComponents/ContactUs/ContactUs'
// import FAQComponent from '../commonComponents/FAQ/FAQComponent'
// import CustomerReviewComponent from '../commonComponents/CustomerReviews/CustomerReviewComponent'
// import WhyTaxEasyComponent from '../commonComponents/WhytaxEasy/WhyTaxEasyComponent'
import ContactUs from '../commonComponents/ContactUs/ContactUs'

const ContactUsRouteComponent = () => {
  return (
    <div>
      <AboutUsInfoComponent/>
      <ContactUs stepInfo={aboutStepList} />
    </div>
  )
}

export default ContactUsRouteComponent