import React, { useEffect, useState } from "react";
import { Form, Button} from "react-bootstrap";
import Classes from "./headerNew.module.scss";
import { isMobile } from "react-device-detect";
import HamburgerIcon from "../../../utils/images/hamburger.png";
import CrossHamburgerIcon from "../../../utils/images/cross-hamburger.png";
import { Link, useParams } from "react-router-dom";
import TaxEasyMainLogo from "../../../utils/images/TaxEasyMainLogo.svg";
import TaxEasyMobileMainLogo from "../../../utils/images/taxEasyMobileMainLogo.png";
import { MdWifiCalling3 } from "react-icons/md";
import { HeaderData } from "../../../utils/constants/constantData";
import classNames from "classnames";
import Typewriter from "typewriter-effect";
import { useSelector, useDispatch } from "react-redux";
import { itrFilingInCitiesUpdate } from "../../../utils/utilities/itrFilingInCitiesSlice";
import GoogleIcon from '../../../utils/images/Google.png'
// import { ThankyouModal } from "../SomeCommonComponents/SomeCommonCompoents";

const HeaderNewComponent = () => {
  const [toggleMenu, setToggleMenu] = useState(true);
  const pathName = window?.location?.pathname ?? "/";
  const [active, setActive] = useState(pathName ?? "/");
  const topHeadingData = useSelector((store) => store.itrFilingInCities.data);
  const isHeaderData = HeaderData.find((info) => info === active);
  const params = useParams();
  const dispatch = useDispatch();
  const [, setText] = useState(topHeadingData);

  useEffect(() => {
    setText(params?.id ?? topHeadingData);
  }, [params?.id, topHeadingData]);

  const handleMobileRoute = (path) => {
    setActive(path);
    setToggleMenu(!toggleMenu);
  };

  return (
    <div
      className={classNames(Classes.headerMainContainer, active==='/company-registration' || active === '/gst' || active === '/about-us' ? Classes.companyHeaderMainContainer:'')}
      style={{ height: active === "/admin" ? "80px" : "" }}
    >
      <div className={classNames(Classes.header, active==='/company-registration' || active === '/gst' || active === '/about-us'?Classes.companyHeader: '')}>
        
        <div className={Classes.logo}>
          <Link to="/">
            <img
              src={isMobile ? TaxEasyMobileMainLogo : TaxEasyMainLogo}
              alt={"tax-easy-logo"}
              onClick={() => handleMobileRoute("/")}
            />
          </Link>
              
        </div>
        
        {isMobile ? (
          <div>
            {isMobile && <a
                    target="_blank"
                    rel="noreferrer"
                    href="tel:+91-9936818000"
                    className={active==='/company-registration' || active==='/gst' || active === '/about-us' ? Classes.mobileNumberButtonWhite : Classes.mobileNumberButtonBlack}
                  >
                    <MdWifiCalling3 />
                  </a>}
            <img
              src={toggleMenu ? HamburgerIcon : CrossHamburgerIcon}
              alt={"hamburger-icon"}
              className={
                toggleMenu ? Classes.hamburger : Classes.crossHamburger
              }
              onClick={() => {
                setToggleMenu(!toggleMenu);
              }}
            />
            <ul
              className={
                toggleMenu
                  ? Classes.headerMobileListClose
                  : Classes.headerMobileList
              }
            >
              <div>
                <img
                  src={toggleMenu ? HamburgerIcon : CrossHamburgerIcon}
                  alt={"hamburger-icon"}
                  className={
                    toggleMenu ? Classes.hamburger : Classes.crossHamburger
                  }
                  onClick={() => {
                    setToggleMenu(!toggleMenu);
                  }}
                />
              </div>
              <Link to="/" key="/">
                <li
                  onClick={() => {
                    handleMobileRoute("/");
                    dispatch(itrFilingInCitiesUpdate("TOP RATED IN DELHI NCR!"));
                  }}
                  className={active === "/" ? Classes.active : ""}
                >
                  Home
                </li>
              </Link>
              <Link to="/company-registration" key="/company">
                <li
                  onClick={() => handleMobileRoute("/company-registration")}
                  className={
                    active === "/company-registration" ? Classes.active : ""
                  }
                >
                  COMPANY/LLP
                </li>
              </Link>
              <Link to="/itr" key="/itr">
                <li
                  onClick={() => handleMobileRoute("/itr")}
                  className={active === "/itr" ? Classes.active : ""}
                >
                  ITR Filing
                </li>
              </Link>
              <Link to="/gst" key="/gst">
                <li
                  onClick={() => handleMobileRoute("/gst")}
                  className={active === "/gst" ? Classes.active : ""}
                >
                  GST
                </li>
              </Link>
              <Link to="/about-us" key="/about">
                <li
                  onClick={() => handleMobileRoute("/about-us")}
                  className={active === "/about-us" ? Classes.active : ""}
                >
                  ABOUT US
                </li>
              </Link>
              <a
                target="_blank"
                rel="noreferrer"
                href="tel:+91-9936818000"
                key="mobile"
              >
                <li className={classNames(Classes.mobile)}>
                  <MdWifiCalling3 />
                  <span>9936818000</span>
                </li>
              </a>
            </ul>
          </div>
        ) : (
          <div className={Classes.headerListContainer}>
            <ul className={classNames(Classes.headerList, active==='/company-registration' || active === '/gst' || active === '/about-us' ? Classes.companyHeaderList:'')}>
              <Link to="/" key="/">
                <li
                  onClick={() => {
                    setActive("/");
                    dispatch(itrFilingInCitiesUpdate("TOP RATED IN DELHI NCR!"));
                  }}
                  className={active === "/" ? Classes.active : ""}
                >
                  HOME
                </li>
              </Link>
              <Link to="/company-registration" key="/company">
                <li
                  onClick={() => setActive("/company-registration")}
                  className={
                    active === "/company-registration" ? Classes.active : ""
                  }
                >
                  COMPANY/LLP
                </li>
              </Link>
              <Link to="/itr" key="/itr">
                <li
                  onClick={() => setActive("/itr")}
                  className={active === "/itr" ? Classes.active : ""}
                >
                  ITR FILING
                </li>
              </Link>
              <Link to="/gst" key="/gst">
                <li
                  onClick={() => setActive("/gst")}
                  className={active === "/gst" ? Classes.active : ""}
                >
                  GST
                </li>
              </Link>
              <Link to="/about-us" key="/about">
                <li
                  onClick={() => setActive("/about-us")}
                  className={active === "/about-us" ? Classes.active : ""}
                >
                  ABOUT US
                </li>
              </Link>
              <a
                target="_blank"
                rel="noreferrer"
                href="tel:+91-9936818000"
                key="mobile"
              >
                <li className={classNames(Classes.mobile)}>
                  <MdWifiCalling3 />
                  <span>9936818000</span>
                </li>
              </a>
            </ul>
          </div>
        )}
      </div>
      {active!=='/company-registration' && active!== '/gst' && active !== '/about-us' && !isHeaderData &&
        HeaderData?.map((info, index) => (
          <>
            {info?.path === active && active !== "/admin" && (
              <div
                key={`header-section-${index}`}
                className={Classes.headerTextSection}
              >
                <div className={Classes.headerSection2}>
                  <p className={Classes.description}>
                    {active !== "/company-registration" ? (
                      <span
                        className={
                          topHeadingData !== "TOP RATED IN DELHI NCR!"
                            ? Classes.headingData
                            : ""
                        }
                      >
                        {topHeadingData}
                      </span>
                    ) : (
                      "TOP RATED IN DELHI NCR!"
                    )}
                  </p>
                  <h1 className={Classes.heading}>
                    <Typewriter
                      style={{ fontSize: "40px" }}
                      options={{
                        strings: [info?.section1?.heading1],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </h1>
                  {info?.section1?.heading2 && (
                    <h1 className={Classes.heading}>
                      {info?.section1?.heading2}
                    </h1>
                  )}
                </div>
                <div className={Classes.headerSection3}>
                  <h2 className={Classes.heading}>{info?.section2?.heading}</h2>
                  <p className={Classes.description1}>
                    {info?.section2?.description1}
                  </p>
                  <p className={Classes.description2}>
                    {info?.section2?.description2}
                  </p>
                  <p className={Classes.description3}>
                    {info?.section2?.description3}
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="tel:+91-9936818000"
                    className={Classes.button}
                  >
                    CALL NOW
                  </a>
                </div>
              </div>
            )}
          </>
        ))}
        {active==='/company-registration' && <CompanyRegisterationHeaderComponent/>}
        {active === '/gst' && <GstHeaderComponent/>}
    </div>
  );
};

export default HeaderNewComponent;

const CompanyRegisterationHeaderComponent = ()=>{


  const [inputData, setInputData] = useState({ name: "", mobile: "", lookingFor: ""});
  const [error, setError] = useState("");
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => setShow(false), 3000);
  //   }
  // }, [show]);

  const handleInputChange = (e) => {
    setError("");
    if (e.target.name === "name") {
      setInputData({ ...inputData, name: e.target.value });
    } else if (e.target.name === "mobile") {
      setInputData({ ...inputData, mobile: e.target.value });
    }else if(e.target.name === "lookingFor"){
      console.log(e.target.value)
      setInputData({...inputData, lookingFor: e.target.value})
    }
  };

  const onSubmitClick = async () => {
    if (
      inputData.mobile !== "" &&
      inputData.mobile?.toString()?.length === 10
    ) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "/contact",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({ ...inputData, page: "COMPANY_REG" }),
          }
        );

        if (response.ok && response.status === 201) {
          // setShow(true);
          setInputData({ name: "", mobile: "", lookingFor: "" });
          window.location.pathname = '/thank-you'
        } else {
          console.log("An error occurred while submitting the data.");
          setInputData({ name: "", mobile: "" , lookingFor: ""});
          setError(
            "Some Error occured. Please Try to submitting the data again or after some time"
          );
        }
      } catch (error) {
        console.log("An error occurred while making the request:", error);
        setInputData({ name: "", mobile: "", lookingFor: "" });
        setError(
          "Some Error occured. Please Try to submitting the data again or after some time"
        );
      }
    } else {
      setError("Please enter a valid mobile number.");
      console.log("Please enter a valid mobile number.");
    }
  };


  return (
    <div className={Classes.companyRegisterationHeaderContainer}>
      <div className={Classes.textContainer}>
        <h1 className={Classes.heading}>Top Company Consultants in Delhi NCR!</h1>
        <ul className={Classes.description}>
          <li>Private Limited Company Registration</li>
          <li>One Person Company (OPC) Registration</li>
          <li>limited Liability Partnership (LLP) Registration</li>
          <li>Section 8 Company Registration (NGO/ Foundation)</li>
          <li>Public Limited Company Registration</li>
          <li>Nidhi Company Registration</li>
          <li>Farmer Producer (FPO) Company registration</li>
        </ul>
        <div className={Classes.typewriterTextContainer}>
        <Typewriter
                      style={{ fontSize: "40px" }}
                      options={{
                        strings: 'GET STARTED @ Just Rs. O FOR FREE CONSULTATION!',
                        autoStart: true,
                        loop: true,
                      }}
                    />
        </div>
        <div className={Classes.googleReviewContainer}>
          <img src={GoogleIcon} alt='Google reviews'/> 
          <div className={Classes.googleTextContainer}>
          <span className={Classes.text}>Google Reviews</span>
          <span className={Classes.rating}>4.9 ★ ★ ★ ★ ★ </span>
          </div>
          <div className={Classes.offerText}>
            <span>IN 10 DAYS</span>
            <span>|</span>
            <span>MOST AFFORDABLE PRICES</span>
          </div>
        </div>
      </div>
      <Form className={Classes.formContainer}>
        <p className={Classes.heading}>Get Instant Quote !</p>
        {/* <ThankyouModal show={show} handleClose={handleClose} /> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              placeholder="Enter Full Name"
              className={Classes.nameInput}
              name="name"
              value={inputData.name}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Control
              type="number"
              placeholder="Enter Mobile Number"
              className={classNames(
                Classes.mobileInput,
                error ? Classes.errorBorder : ""
              )}
              name="mobile"
              value={inputData.mobile}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group>
          <Form.Select aria-label="Default select example" value={inputData.lookingFor} onChange={handleInputChange} name="lookingFor">
      <option value="">Looking For</option>
      <option value="Pvt. Ltd. Registration">Pvt. Ltd. Registration </option>
      <option value="One Person Company Registration">One Person Company Registration</option>
      <option value="LLP Registration">LLP Registration</option>
      <option value="Other Registrations">Other Registrations</option>
    </Form.Select>
          </Form.Group>
          <p className={Classes.errorMsg}>{error}</p>
          <Button
            variant="warning"
            className={Classes.submitBtn}
            onClick={onSubmitClick}
          >
            SUBMIT NOW
          </Button>
          <p className={Classes.contactInfo}>Call Now: 9936818000; 9555400354</p>
        </Form>
      </div>
  )
}


const GstHeaderComponent = ()=>{
  const [inputData, setInputData] = useState({ name: "", mobile: ""});
  const [error, setError] = useState(" ");
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => setShow(false), 3000);
  //   }
  // }, [show]);

  const handleInputChange = (e) => {
    setError("");
    if (e.target.name === "name") {
      setInputData({ ...inputData, name: e.target.value });
    } else if (e.target.name === "mobile") {
      setInputData({ ...inputData, mobile: e.target.value });
    }
  };

  const onSubmitClick = async () => {
    if (
      inputData.mobile !== "" &&
      inputData.mobile?.toString()?.length === 10
    ) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL + "/contact",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({ ...inputData, page: "GST" }),
          }
        );

        if (response.ok && response.status === 201) {
          // setShow(true);
          setInputData({ name: "", mobile: ""});
          window.location.pathname = '/thank-you'
        } else {
          console.log("An error occurred while submitting the data.");
          setInputData({ name: "", mobile: ""});
          setError(
            "Some Error occured. Please Try to submitting the data again or after some time"
          );
        }
      } catch (error) {
        console.log("An error occurred while making the request:", error);
        setInputData({ name: "", mobile: ""});
        setError(
          "Some Error occured. Please Try to submitting the data again or after some time"
        );
      }
    } else {
      setError("Please enter a valid mobile number.");
      console.log("Please enter a valid mobile number.");
    }
  };


  return (
    <div className={Classes.companyRegisterationHeaderContainer}>
      <div className={Classes.textContainer}>
        <h1 className={Classes.heading}>Top GST Consultants in Delhi NCR!</h1>
        <h2 className={Classes.subHeading}>All GST Solutions available with TaxEasy</h2>
        <ul className={Classes.gstDescription}>
          <div className={Classes.columns}>
          <div><li><span>✔️</span>GST Registration</li>
          <li><span>✔️</span>GST Return Filing</li>
          <li><span>✔️</span>GST for Exporters</li></div>
          
          <div><li><span>✔️</span>GSTR-9/9C Annual Filing</li>
          <li><span>✔️</span>GST LUT Form</li>
          <li><span>✔️</span>GST for Freelancers</li></div>
          </div> 
          <li><span>✔️</span>Amazon/Flipkart/Meesho Sellers GST Registration & Returns</li>
        </ul>
        <div className={Classes.typewriterTextContainer}>
        <Typewriter
                      style={{ fontSize: "40px" }}
                      options={{
                        strings: 'GET STARTED @ Just Rs. O FOR FREE CONSULTATION!',
                        autoStart: true,
                        loop: true,
                      }}
                    />
        </div>
        <div className={Classes.googleReviewContainer}>
          <img src={GoogleIcon} alt='Google reviews'/> 
          <div className={Classes.googleTextContainer}>
          <span className={Classes.text}>Google Reviews</span>
          <span className={Classes.rating}>4.9 ★ ★ ★ ★ ★ </span>
          </div>
          <div className={Classes.offerText}>
            <span>GET PERSONAL EXPERT ASSISTANCE!</span>
          </div>
        </div>
      </div>
      <Form className={Classes.formContainer}>
        <p className={Classes.heading}>Get Free Consultation By Expert!</p>
        {/* <ThankyouModal show={show} handleClose={handleClose} /> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="text"
              placeholder="Enter Full Name"
              className={Classes.nameInput}
              name="name"
              value={inputData.name}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2">
            <Form.Control
              type="number"
              placeholder="Enter Mobile Number"
              className={classNames(
                Classes.mobileInput,
                error ? Classes.errorBorder : ""
              )}
              name="mobile"
              value={inputData.mobile}
              onChange={handleInputChange}
            />
          </Form.Group>
          <p className={Classes.errorMsg}>{error}</p>
          <Button
            variant="warning"
            className={Classes.submitBtn}
            onClick={onSubmitClick}
          >
            SUBMIT NOW
          </Button>
          <p className={Classes.contactInfo}>Call Now: 9936818000; 9555400354</p>
        </Form>
      </div>
  )
}
